<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="d_f">
      <div class="flex_1">
        <!--基本信息-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>基本信息</div>
          </div>
          <el-row class="top_info">
            <el-col :span="8"><span>行程单号：{{info.ticketItinerary.serialNo}}</span></el-col>
            <el-col :span="8"><span>行程单状态：{{info.ticketItinerary.approveStatus ? staArr[info.ticketItinerary.approveStatus].name : '--'}}</span></el-col>
            <el-col :span="8"><span>总票数：{{info.ticketItinerary.totalPurchaseNumber}}</span></el-col>
          </el-row>
          <el-row class="top_info">
            <el-col :span="8"><span>总金额：{{info.ticketItinerary.totalSellPrice ? Number(info.ticketItinerary.totalSellPrice /100).toFixed(2) : 0}}</span></el-col>
            <el-col :span="8"><span>提交时间：{{info.ticketItinerary.creatorTime}}</span></el-col>
            <el-col :span="8"><span>审核时间：{{info.ticketItinerary.approveTime}}</span></el-col>
          </el-row>
          <el-row class="top_info">
            <el-col :span="8"><span>审核意见：{{info.ticketItinerary.opinion}}</span></el-col>
          </el-row>
          <!--倒计时-->
          <!--<div class="t_cut d_f ali_c" v-if="info.ticketItinerary.approveStatus == 2 && info.ticketItinerary.itineraryStatus == 0 && pTime">
            <template v-if="time  && time!='行程单超时，已自动关闭'">
              <img src="../assets/images/trip_alark.png" alt="">
              <span>请在</span>
              <span class="red_text">{{time}}</span>
              <span>内前往下单，超时行程单将自动取消。</span>
            </template >
            <span v-else>{{time}}</span>
          </div>-->
          <!--按钮-->
          <!--<div class="ch_btn" v-if="time ">
            <div class="cur_p" @click="dialogVisible = true" v-if="info.ticketItinerary.approveStatus == 2 && info.ticketItinerary.itineraryStatus == 0 && time && time!='行程单超时，已自动关闭'">前往下单</div>
            <div class="ac cur_p" @click="changeRow" v-if="info.ticketItinerary.approveStatus == 0 && info.ticketItinerary.itineraryStatus == 0">修改行程单</div>
          </div>-->
          <!--按钮-->
          <div class="ch_btn">
            <div class="ac cur_p" @click="changeRow" v-if="info.ticketItinerary.approveStatus == null && info.ticketItinerary.itineraryStatus == 0">修改行程单</div>
          </div>
        </div>
        <!--预订人信息-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>预订人信息</div>
          </div>
          <div class="t_r_info">
            <span>行程单姓名：{{info.ticketItinerary.reservationPersonName}}</span>
            <span class="ml">预订人手机号：{{info.ticketItinerary.reservationPersonTelephone}}</span>
          </div>
        </div>
        <!--游客信息-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>游客信息</div>
          </div>

          <el-table
            ref="multipleTable"
            :data="info.itineraryDetails"
            stripe
            :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column label="商品名称" align="center">
              <template #default="scope">{{ scope.row.commodityName }}</template>
            </el-table-column>
            <el-table-column prop="visitorName" label="游客姓名" align="center"> </el-table-column>
            <el-table-column prop="credentialsType" label="证件类型" align="center" show-overflow-tooltip>
              <template #default="scope"><span>{{getZJlX(scope.row.credentialsType)}}</span></template>
            </el-table-column>
            <el-table-column prop="certificateNumber" label="证件号码" align="center" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="td_r"></div>
    </div>
    <div class="td_rp p_f">
      <div class="tdr_title p_r t_a">
        <span>行程单详情</span>
        <div class="p_a"></div>
      </div>
      <div class="ch_btn">
        <div @click="$router.go(-1)">返回上一级</div>
      </div>
    </div>

    <!--payPop-->
    <el-dialog
      v-model="dialogVisible"
      width="40%"
      :show-close="false"
    >
      <template #title>
        <div class="uc_title d_f ali_c flex_1">
          <div class="uc_green"></div>
          <div>温馨提示</div>
          <div class="flex_1 t_r" @click="dialogVisible = false">
            <img class="close" src="../assets/images/bk_close.png" alt="">
          </div>
        </div>
      </template>
      <div class="t_a td_pop_con">
        <img src="../assets/images/td_pop.png" alt="">
        <div>前往支付后，行程单将无法再修改</div>
        <!--<div>请在<span>{{time}}</span>分钟内完成支付，否则订单自动取消</div>-->
      </div>

      <template #footer>

        <div class="t_a ch_btn td">
          <div class="cp_btn"  @click="goPay()">确定</div>
          <div class="cp_btn ac" @click="dialogVisible = false">取消</div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "tripDetail",
    data() {
      return {
        seconds:null,
        dialogVisible:false,
        info:{ticketItinerary:{},itineraryDetails:[]},
        cardArr:[],
        interval:null,
        showTimeC:null,
        pTime:null,
        time:'',
        staArr:[
          {value:0,name:'待提交'},
          {value:1,name:'审核中'},
          {value:2,name:'审核通过'},
          {value:3,name:'审核失败'},
          {value:4,name:'已取消'},
        ],
      }
    },
    created() {
      this.getInfo();
      this.queryWinCredentials();
    },
    onBeforeRouteLeave(){
      this.clearInter();
    },
    methods: {
      changeRow(){

        this.$router.push({path:'/fillIn',query:{id:this.$route.query.id}});
      },
      getZJlX(type){
        let name = '';
        this.cardArr.map(item => {
          if(item.value == type){
            name = item.name
          }
        });
        return name
      },
      //获取证件类型
      queryWinCredentials(){
        this.$api.queryWinCredentials().then(res => {
          this.cardArr = res.data;
        })
      },
      //获取服务器时间
      getSysTime(){
        this.time = '';
        this.$api.getSysTime().then(res => {
          var u = navigator.userAgent;
          var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
          var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1; //ios终端
          let endtime = '';
          if(isiOS){
            endtime = new Date(this.pTime.replace(/-/g,'/')).getTime()+7200000;
          }else{
            endtime = new Date(this.pTime).getTime()+7200000;
          }
          // endtime = new Date(this.pTime).getTime()+900000;
          var nowtime = Number(res.data)*1000;
          // var nowtime = new Date().getTime();
          this.showTimeC = endtime-nowtime;
          this.getTime()
        })
      },
      //倒计时
      getTime(){
        let _t = this;
        _t.interVal = null;
        _t.interVal = window.setInterval (function () {
          _t.showTime();
        }, 1000);
      },
      showTime(){
        let _t = this;
        _t.showTimeC = _t.showTimeC -1000;
        var  leftm = Math.floor(_t.showTimeC /(1000*60)%60),  //计算分钟数
          lefts = Math.floor(_t.showTimeC /1000%60),  //计算秒数
          lefth = Math.floor(_t.showTimeC  / 1000 / 60 / 60);  //计算秒数
        lefth = lefth>9 ? leftm : '0'+lefth;
        leftm = leftm>9 ? leftm : '0'+leftm;
        lefts = lefts>9 ? lefts : '0'+lefts;
        if(_t.showTimeC > 0){
          _t.time = lefth+"小时"+ leftm + "分" + lefts + "秒";  //返回倒计时的字符串
        }else{
          _t.clearInter();
          _t.time =  "行程超时，已自动关闭" ;  //返回倒计时的字符串

          return ''
        }
      },
      clearInter(){
        let _t = this;
        window.clearInterval(_t.interVal);
        _t.interVal = null;
        _t.time = '';
      },

      getInfo(){
        this.$api.queryItineraryGroup({itineraryId :this.$route.query.id}).then(res => {
          this.info = res.data;
          this.pTime = this.info.ticketItinerary.approveTime;
          if(this.pTime){
            this.getSysTime();
          }
        })
      },
      // 前往下单,弹窗确认
      goPay(){
        this.$api.itineraryCreateOrder({itineraryId:this.$route.query.id}).then(res => {
          this.$router.push({path:'/pay',query:{id:res.data.orderId,num:res.data.orderNumber}});
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  :v-deep .el-dialog__body{
    padding: 0;
  }
  .td_pop_con{
    img{
      margin-bottom: 34px;
      width: 202px;
      height: 202px;
    }
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 73px;
    span {
      color: #ff2626;
    }
  }
  .t_r_info{
    .ml{
      margin-left: 135px;
    }
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .ch_btn{
    div{
      display: inline-block;
      &.ac{
        margin-left: 42px;
      }
    }
    text-align: center;
  }
  .t_cut{
    img{
      width: 30px;
      margin-right: 20px;
    }
    box-sizing: border-box;
    padding: 0 30px;
    height: 59px;
    background: linear-gradient(to right, #FFF6D7, #ffffff);
    border-radius: 6px;
    font-size: 22px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 20px;
  }
  .top_info{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .uc_title{
    margin-bottom: 38px;
  }
</style>
